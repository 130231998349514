@use 'variables' as *;

.synply-loader {
  &__animation {
    animation-name: breathing;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    animation-duration: 1s;
    display: inline-block;
  }
}

@keyframes breathing {
  0% {
    transform: scale(0.9);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9);
  }
}

.synply-loader {
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 150px;
  width: 150px;

  &.in-header {
    position: absolute !important;
    top: -10px !important;
    display: none;

    @media (max-width: $layout-breakpoint-xs),
      (max-height: $layout-breakpoint-xs) {
      display: block;
    }
  }

  @media (max-width: $layout-breakpoint-xs),
    (max-height: $layout-breakpoint-xs) {
    height: 46px;
    width: 46px;
    top: 10px;
    left: 10px;
  }

  @media (max-width: $layout-breakpoint-sm) {
    height: 46px;
    width: 46px;
    top: 10px;
    left: 10px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.logo {
  position: fixed;
  top: 20px;
  left: 20px;
  height: 100px;
  width: 100px;

  @media (max-width: $layout-breakpoint-xs),
    (max-height: $layout-breakpoint-xs) {
    height: 46px;
    width: 46px;
    top: 10px;
    left: 10px;
  }

  @media (max-width: $layout-breakpoint-sm) {
    height: 46px;
    width: 46px;
    top: 10px;
    left: 10px;
  }
}

.loader-container {
  background-color: var(--app-main-background);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
